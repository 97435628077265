/* eslint-disable complexity */
import {later} from '@mol-fe/mol-fe-async';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {getMailplusChannelUrl} from '../api';

const getMailplusNavItem = ({isSelected = false, topNav = false} = {}) => {
  const mailplusNavItem = document.createElement('li');

  mailplusNavItem.classList.add('mailplus-nav');
  mailplusNavItem.innerHTML = `
    <span class="${isSelected && topNav ? 'link-wocc linkro-wocc' : 'link-gr6ox linkro-ccox'} ${isSelected ? 'selected' : ''}">
      <a class="${isSelected && topNav ? 'no-border' : ''}" href="${getMailplusChannelUrl()}">
        Mail+
      </a>
    </span>
  `;

  return mailplusNavItem;
};

export const initDesktopNavigation = async () => {
  const {pageType, isPaywalled, channel, geo} = getPageCriteria();
  const isSelected = pageType === 'article' && isPaywalled || pageType === 'channel' && channel === 'mailplus';
  const primaryNavEl = document.querySelector('.page-header .nav-primary');
  const secondaryNavEl = document.querySelector('.page-header > .nav-secondary-container');

  if (isSelected) {
    const currentlySelectedNavItem = primaryNavEl.querySelector('span.selected');
    const itemAfterSelected = primaryNavEl.querySelector('li:not(:first-child) .no-border');

    if (currentlySelectedNavItem) {
      currentlySelectedNavItem.classList.remove('link-wocc', 'linkro-wocc', 'selected');
      currentlySelectedNavItem.classList.add('link-gr6ox', 'linkro-ccox');
    }

    if (itemAfterSelected) {
      itemAfterSelected.classList.remove('no-border');
    }

    if (secondaryNavEl) {
      const secondaryNavList = secondaryNavEl.querySelector('ul');

      if (secondaryNavList) {
        secondaryNavList.remove();
      }

      secondaryNavEl.classList.add('mailplus');
    }
  }

  if (geo === 'CA') {
    const puzzlesNavLink = primaryNavEl.querySelector('a[href="/puzzles/index.html"]');
    const puzzlesNav = puzzlesNavLink && puzzlesNavLink.closest('li');

    if (puzzlesNav) {
      puzzlesNav.remove();
    }

    const wellnessUsNavLink = primaryNavEl.querySelector('a[href="/wellness-us/index.html"]');
    const wellnessUsNav = wellnessUsNavLink && wellnessUsNavLink.closest('li');
    const wellnessUsNavSpan = wellnessUsNav && wellnessUsNav.querySelector('span');
    const isPodcastsSelected = channel === 'podcasts';
    const nextSiblingLink = wellnessUsNav && wellnessUsNav.nextElementSibling.querySelector('a');

    if (wellnessUsNav) {
      wellnessUsNavLink.innerHTML = 'Podcasts';
      wellnessUsNavLink.setAttribute('href', '/podcasts/index.html');
      wellnessUsNav.classList.remove('wellness');
      wellnessUsNav.classList.add('podcasts');

      if (isPodcastsSelected) {
        wellnessUsNavSpan.classList.remove('link-gr6ox', 'linkro-ccox');
        wellnessUsNavSpan.classList.add('link-wocc', 'linkro-wocc');
        wellnessUsNavSpan.classList.add('selected');

        if (nextSiblingLink) {
          nextSiblingLink.classList.add('no-border');
        }
      }
    }
  }

  primaryNavEl.appendChild(
    getMailplusNavItem({
      isSelected,
      topNav: true
    })
  );

  await later('DOM_READY');

  const footerPrimaryNavEl = document.querySelector('.footer .nav-primary');

  if (footerPrimaryNavEl) {
    if (geo === 'CA') {
      const puzzlesNavLink = footerPrimaryNavEl.querySelector('a[href="/puzzles/index.html"]');
      const puzzlesNav = puzzlesNavLink && puzzlesNavLink.closest('li');

      if (puzzlesNav) {
        puzzlesNav.remove();
      }

      const wellnessUsNavLink = footerPrimaryNavEl.querySelector('a[href="/wellness-us/index.html"]');
      const wellnessUsNav = wellnessUsNavLink && wellnessUsNavLink.closest('li');
      const wellnessUsNavSpan = wellnessUsNav && wellnessUsNav.querySelector('span');
      const isPodcastsSelected = channel === 'podcasts';
      const nextSiblingLink = wellnessUsNav && wellnessUsNav.nextElementSibling.querySelector('a');

      if (wellnessUsNav) {
        wellnessUsNavLink.innerHTML = 'Podcasts';
        wellnessUsNavLink.setAttribute('href', '/podcasts/index.html');
        wellnessUsNav.classList.remove('wellness');
        wellnessUsNav.classList.add('podcasts');

        if (isPodcastsSelected) {
          wellnessUsNavSpan.classList.remove('link-gr6ox', 'linkro-ccox');
          wellnessUsNavSpan.classList.add('link-wocc', 'linkro-wocc');
          wellnessUsNavSpan.classList.add('selected');

          if (nextSiblingLink) {
            nextSiblingLink.classList.add('no-border');
          }
        }
      }
    }

    footerPrimaryNavEl.appendChild(
      getMailplusNavItem({
        isSelected,
        topNav: false
      })
    );
  }
};
