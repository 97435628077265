import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';

/* eslint-disable complexity */
export const createPuffItem = (article, {headlineMaxLength = 160, useSocialHeadline = false} = {}, includeAdMarker) => {
  const {domain, environment, renderPlatform} = getPageMetadata();
  const {channel} = getPageCriteria();
  const item = includeAdMarker ? document.createElement('li', {is: 'ad-marker-li'}) : document.createElement('li');

  if (includeAdMarker) {
    item.dataset.adMarkerGroup = 'puff-list';
  }

  item.dataset.autoPuffItem = true;

  if (
    Array.isArray(article.xpModulesUsed) &&
    article.xpModulesUsed.some((xpModule) => xpModule.startsWith('mol-fe-xpmodule-video-hero'))
  ) {
    item.classList.add('video-led');
  }

  let headline = useSocialHeadline ? article.headline : article.fullHeadline || article.headline;

  if (headline.length > headlineMaxLength) {
    headline = headline.substring(0, headlineMaxLength) + '...';
  }

  let url = article.url;

  if (article.channel === 'money' && domain !== 'thisismoney') {
    const moneyIco = `mol_${renderPlatform}_${channel}`;

    url = `https://www.thisismoney${environment === 'integration' ? 'int' : ''}.co.uk${url}?ico=${moneyIco}`;
  }

  if (article.channel !== 'money' && domain === 'thisismoney') {
    const molIco = `tim_autopuff_${renderPlatform}_${channel}`;

    url = `https://www.dailymail${environment === 'integration' ? 'int' : ''}.co.uk${url}?ico=${molIco}`;
  }

  item.innerHTML = `
    <a href="${url}">
      <img src="${article.puffImage || article.imgUrl}" height="115" width="154" alt="" loading="lazy">
      <span class="pufftext">
        <span class="arrow-small-r"></span>
        <strong>
          ${article.isPaywalled ? '<span class="is-paywalled"></span>' : ''}${headline}
        </strong>
      </span>
    </a>
  `;

  if (article.isPaywalled) {
    item.dataset.isPaywalled = true;
  }

  return item;
};

const getArticleIdFromPuffItem = (puffItem) => {
  try {
    const linkEl = puffItem.querySelector('a');

    if (!linkEl) {
      return null;
    }

    const url = linkEl.href;
    const articleId = url.match(/\/article-(\d+)\//)[1];

    return Number(articleId) || null;
  } catch (error) {
    return null;
  }
};

let hasChangedTrackModule = false;

const puffTitles = {
  sport: 'STARS OF SPORT'
};

const changeTrackModule = (
  puffList,
  channel
) => {
  if (hasChangedTrackModule) {
    return;
  }

  hasChangedTrackModule = true;

  try {
    puffList.dataset.trackModule = puffList.dataset.trackModule.replace(/\^puff/, '^autopuff');
    puffList.parentNode.classList.remove('femail');
    puffList.parentNode.classList.remove('travel');
    puffList.parentNode.classList.add(channel || 'home');
    puffList.parentNode.dataset.channelColor = channel || 'home';
    puffList.querySelector('h3').innerHTML = channel && puffTitles[channel] || 'TOP STORIES';

    const moreDontMiss = document.querySelectorAll('.puff ul')[1];

    if (moreDontMiss && moreDontMiss.parentNode.parentNode.classList.contains('tvshowbiz')) {
      moreDontMiss.parentNode.parentNode.classList.remove('tvshowbiz');
      moreDontMiss.parentNode.parentNode.classList.add('femail');
      moreDontMiss.parentNode.parentNode.dataset.channelColor = 'femail';
      moreDontMiss.parentNode.querySelector('h3').innerHTML = 'DON\'T MISS';
    }
  } catch (error) {
    // ignore
  }
};

const clonePuffNode = (puffNode) => {
  const clone = puffNode.cloneNode(true);

  try {
    const img = clone.querySelector('img');

    if (img.dataset.src) {
      img.loading = 'lazy';
      img.src = img.dataset.src;
      delete img.dataset.src;
    }
  } catch (error) {
    // ignore
  }

  return clone;
};

export const placeArticlesInPuffs = (
  articles,
  paywalledArticles = [],
  channel
) => {
  if (!articles || !articles.length) {
    return;
  }

  const puffItemCandidates = Array.from(document.querySelectorAll('.puff ul li:not([data-is-sponsored]):not(.exclusive)'))
    .filter((puffItem) => !puffItem.closest('.royal_wedding'))
    .filter((puffItem) => puffItem && puffItem.children && puffItem.children[0] && puffItem.children[0].tagName === 'A');

  if (!puffItemCandidates.length) {
    return;
  }

  const specialArticleIds = Array.from(document.querySelectorAll('.puff ul li[data-is-sponsored],.puff ul li.exclusive'))
    .map(getArticleIdFromPuffItem).filter(Boolean);
  const replacementArticles = articles.slice().filter((article) => !specialArticleIds.includes(article.articleId));
  const replacementArticleIds = replacementArticles.map((article) => article.articleId);
  const puffArticleIds = puffItemCandidates.map(getArticleIdFromPuffItem).filter(Boolean);
  const puffItemQueue = [];
  const filteredPaywalled = paywalledArticles
    .filter((article) => !specialArticleIds.includes(article.articleId))
    .filter((article) => !replacementArticleIds.includes(article.articleId));

  let position = 1;

  for (const puffItem of puffItemCandidates) {
    const isPaywalledForced = (position - 3) % 4 === 0 && replacementArticles.length > 0 && filteredPaywalled.length > 0;
    const replacementArticle = isPaywalledForced ? filteredPaywalled.shift() : replacementArticles.shift();
    const puffArticleId = getArticleIdFromPuffItem(puffItem);
    const replacementArticleId = replacementArticle && Number(replacementArticle.articleId);

    if (!puffItem.dataset.autoPuffReorder) {
      if (replacementArticle && replacementArticleId !== puffArticleId) {
        const replacementPuffItem = createPuffItem(replacementArticle);

        if (!hasChangedTrackModule) {
          const puffList = puffItem.closest('.puff');

          changeTrackModule(puffList, channel);
        }

        if (puffArticleIds.includes(replacementArticleId)) {
          const existingPuffToReorder = puffItemCandidates.find((item) => item.querySelector('a').getAttribute('href') === replacementArticle.url);

          if (existingPuffToReorder) {
            const existingPuffClone = clonePuffNode(existingPuffToReorder);

            existingPuffToReorder.dataset.autoPuffReorderSource = true;
            existingPuffClone.dataset.autoPuffReorderTarget = true;
            if (!puffItem.dataset.autoPuffReorderSource && !replacementArticleIds.includes(puffArticleId)) {
              puffItemQueue.push(puffItem);
            }
            puffItem.replaceWith(existingPuffClone);
          }
        } else {
          if (!puffItem.dataset.autoPuffReorderSource && !replacementArticleIds.includes(puffArticleId)) {
            puffItemQueue.push(puffItem);
          }
          puffItem.replaceWith(replacementPuffItem);
        }
      } else if (puffItemQueue.length && !replacementArticle) {
        const movedPuff = puffItemQueue.shift();

        movedPuff.dataset.autoPuffMoved = true;
        if (!puffItem.dataset.autoPuffReorderSource) {
          puffItemQueue.push(puffItem);
        }
        puffItem.replaceWith(movedPuff);
      }
    }

    position++;
  }
};
