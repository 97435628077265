/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents, fireBidmaxTrackingEvent} from '@mol-fe/mol-fe-tracking';
import {getRankedArticles} from './api';
import {clearPlaceHolder, replaceRelated, MODULE_NAME} from './related';
import {insertRelatedPlaceholders, isNearPromotional} from './insert';
import {replaceRelatedWithRoyalsPromo} from './royalsPromo';

export const replaceRelatedElement = async ({
  relatedElement,
  useSimilarArticles = false,
  instanceIndex = 0,
  topicGroup = null,
  isInlinePaywall = false
}) => {
  if (topicGroup === 'royals' && instanceIndex === 1) {
    replaceRelatedWithRoyalsPromo(relatedElement);

    return;
  }

  const articles = await getRankedArticles({
    topicGroup,
    useSimilarArticles
  });

  replaceRelated({
    articles,
    instanceIndex,
    isInlinePaywall,
    relatedElement,
    topicGroup
  });
};

const AGE_THRESHOLD_SIMILAR = 30 * 24 * 60 * 60 * 1000;
const MODULE_COUNT = 1;
const EXCLUDED_TOPICS = ['Mail Best', 'Mail Best US'];
const EXCLUDED_SUBCHANNELS = ['sportsbets'];

export const init = async () => {
  try {
    const {articleType, pageType, channel, sponsored, subchannel} = getPageCriteria();
    const {domain, topics, publishedDate} = getPageMetadata();

    if (pageType !== 'article' || articleType === 'feature' || domain === 'thisismoney' || channel === 'money' || sponsored === 'true' || sponsored === true) {
      return;
    }

    if (EXCLUDED_SUBCHANNELS.includes(subchannel)) {
      return;
    }

    if (topics && EXCLUDED_TOPICS.some((excludedTopic) => topics.includes(excludedTopic))) {
      return;
    }

    await later('DOM_READY');

    const oldRelatedModules = Array.from(document.querySelectorAll('.related-with-thumbs, .related-carousel'));
    const relatedContentContainers = insertRelatedPlaceholders(MODULE_COUNT, oldRelatedModules);

    if (!relatedContentContainers.length) {
      return;
    }

    const publishedTS = publishedDate && publishedDate.getTime() || 0;
    const isNewerArticle = Date.now() - publishedTS < AGE_THRESHOLD_SIMILAR;
    const topicGroup = topics && topics.includes('Royals') ? 'royals' : null;
    const useSimilarArticlesForFirst = !topicGroup && isNewerArticle && topics && topics.length;

    relatedContentContainers.forEach((relatedElement, idx) => {
      if (idx > 0 && isNearPromotional(relatedElement)) {
        clearPlaceHolder(relatedElement);

        return;
      }

      fireBidmaxTrackingEvent('impression', MODULE_NAME);
      pageEvents.publish(pageEvents.RELATED_REPLACE_IMPRESSION);

      const useSimilarArticles = useSimilarArticlesForFirst && idx === 0;

      const observer = new IntersectionObserver((entries, obs) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            replaceRelatedElement({
              instanceIndex: idx,
              relatedElement,
              topicGroup,
              useSimilarArticles
            });

            obs.unobserve(relatedElement);

            return;
          }
        }
      }, {rootMargin: '2000px'});

      observer.observe(relatedElement);
    });
  } catch (error) {
    logger.error('[mol-fe-related-replace] Error initialising ', error);
  }
};
