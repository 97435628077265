/* eslint-disable complexity */
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {getIsTim, getMailplusChannelUrl} from '../api';

const THEME_COLOR = '#1e3264';

const setThemeColorMeta = () => {
  const themeColorMeta = document.querySelector('meta[name="theme-color"]') || document.createElement('meta');

  themeColorMeta.setAttribute('name', 'theme-color');
  themeColorMeta.setAttribute('content', THEME_COLOR);

  document.head.appendChild(themeColorMeta);
};

export const initStaticMenubar = (menubarEl) => {
  const isPaywallEligible = window.getIsPaywallEligible && window.getIsPaywallEligible();
  const {pageType, isPaywalled, channel, subchannel} = getPageCriteria();
  const isTim = getIsTim();
  const isPaywalledArticle = pageType === 'article' && isPaywalled;
  const isMailplusChannelPage = pageType === 'channel' && (channel === 'mailplus' || subchannel === 'mailplus' || subchannel === 'mailplusmoney');

  if (!isPaywalledArticle && !isMailplusChannelPage || !isMailplusChannelPage && !isPaywallEligible) {
    return;
  }

  menubarEl.classList.add(isTim ? 'mailplus-menubar-tim' : 'mailplus-menubar');

  if (!isTim) {
    document.documentElement.classList.add('has-mailplus-theme');
  }

  const channelSpan = menubarEl.querySelector('[data-track-module*="masthead-channel"] span');
  const channelLink = menubarEl.querySelector('[data-track-module*="masthead-channel"] a') || document.createElement('a');

  channelLink.setAttribute('href', getMailplusChannelUrl());
  channelLink.innerHTML = 'Mail+';
  channelLink.classList.add('mailplus-channel-link');

  if (channelSpan) {
    channelSpan.replaceWith(channelLink);
  }

  setThemeColorMeta();
};
