import {getConfig} from '@mol-fe/mol-fe-components';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import infiniteScroll from './infiniteScroll';

Promise.all([
  getConfig('molFeInfiniteScroll'),
  later('DOM_READY')
]).then(([config = {}]) => {
  const {
    selector,
    scrollCheckSelector,
    fetchUrl
  } = config;

  if (!fetchUrl) {
    // eslint-disable-next-line no-console
    console.warning('molFeInfiniteScroll : fetchUrl is required');

    return null;
  }

  const element = selector && document.body.querySelector(selector) || document.body.querySelector('.infinite-scroll');

  if (element) {
    const scrollCheckElement = scrollCheckSelector && document.body.querySelector(scrollCheckSelector) || window;

    return infiniteScroll({
      element,
      fetchUrl,
      scrollCheckElement
    });
  }

  // eslint-disable-next-line no-console
  console.warning(`molFeInfiniteScroll : can't find ${element}`);

  return null;
}).catch((error) => logger.error(error.message));
