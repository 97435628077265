/* eslint-disable complexity */
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {getIsTim, getMailplusChannelUrl} from '../api';

const IMAGE_URL_PAYWALLED = 'https://i.dailymail.co.uk/static/mol-fe/static/configs/mol-fe-paywall/mastheads/mailplus_article_masthead_v2.png';
const IMAGE_URL_PAYWALLED_TIM = 'https://i.dailymail.co.uk/static/mol-fe/static/configs/mol-fe-paywall/mastheads/mailplus_article_masthead_tim.jpg';

const getLinkElement = () => {
  const {pageType, channel, subchannel} = getPageCriteria();

  if (pageType === 'channel' && (channel === 'mailplus' || subchannel === 'mailplus' || subchannel === 'mailplusmoney')) {
    return null;
  }

  const href = getMailplusChannelUrl();
  const linkRel = null;
  const linkTarget = null;
  const linkWidth = '300px';

  const link = document.querySelector('[data-paywall-article-masthead]') || document.createElement('a');

  link.href = href;
  link.dataset.paywallArticleMasthead = true;

  if (linkTarget) {
    link.target = linkTarget;
  }

  if (linkRel) {
    link.rel = linkRel;
  }

  link.style.position = 'absolute';
  link.style.zIndex = 10;
  link.style.top = '0px';
  link.style.right = '0px';
  link.style.height = '91px';
  link.style.width = linkWidth;

  link.addEventListener('click', () => {
    pageEvents.publish(pageEvents.PAYWALL_MASTHEAD_ARTICLE_CLICK);
  });

  return link;
};

export const initStaticMasthead = (mastheadEl) => {
  const isPaywallEligible = window.getIsPaywallEligible && window.getIsPaywallEligible();
  const {pageType, isPaywalled, channel, subchannel} = getPageCriteria();
  const isTim = getIsTim();
  const isPaywalledArticle = pageType === 'article' && isPaywalled;
  const isMailplusChannelPage = pageType === 'channel' && (channel === 'mailplus' || subchannel === 'mailplus' || subchannel === 'mailplusmoney');

  if (!isPaywalledArticle && !isMailplusChannelPage || !isMailplusChannelPage && !isPaywallEligible) {
    return;
  }

  const bgImage = isTim ? IMAGE_URL_PAYWALLED_TIM : IMAGE_URL_PAYWALLED;
  const backgroundSize = 'cover';
  const link = getLinkElement();

  if (link) {
    mastheadEl.appendChild(link);
  }

  mastheadEl.style.backgroundImage = `url(${bgImage})`;

  if (backgroundSize) {
    mastheadEl.style.backgroundSize = backgroundSize;
  }

  mastheadEl.classList.add('masthead-paywalled');
  document.documentElement.classList.add(isTim ? 'has-mailplus-masthead-tim' : 'has-mailplus-masthead');

  if (!isTim) {
    document.documentElement.classList.add('has-mailplus-theme');
  }
};
