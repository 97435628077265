/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import URL from 'url-parse';
import {getStorageSession, setStorageSession} from './storage';
import {addMobileMenuButton, addDesktopMastheadButton, getDestinationUrl} from './mount';

const ARTICLE_REGEX = /article-(\d+)/;
const MOL_DOMAIN_REGEX = /dailymail(int)?\.(co\.uk|com)/;
const MAX_SESSION_LIFETIME = 30 * 60 * 1000;

const asToastIco = (isActive) => {
  const {channel} = getPageCriteria();

  return isActive ? `toast_new_${channel}` : `toast_existing_${channel}`;
};

const getArticleId = (node) => {
  const match = node.href.match(ARTICLE_REGEX);

  if (match && match[1]) {
    return match[1];
  }

  return null;
};

const setAdvertsIco = () => {
  if (!window.adverts) {
    window.adverts = {};
  }

  if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    data: {
      referrer: 'dailymail'
    },
    scope: 'metadata'
  });
};

const addToPage = async (origin) => {
  const {renderPlatform} = getPageMetadata();
  const relevantElement = document.querySelector('.page-header .masthead, .tim-mobile header');

  window.timCameFromMolUrl = getDestinationUrl(origin);
  document.documentElement.classList.add('came-from-mol');
  setAdvertsIco();

  if (!relevantElement) {
    await later('DOM_READY');
  }

  if (renderPlatform === 'mobile') {
    addMobileMenuButton(origin);
  } else if (renderPlatform === 'desktop') {
    addDesktopMastheadButton(origin);
  }
};

const asToastLink = (link, isActive) => {
  const value = asToastIco(isActive);

  const url = link.getAttribute('href');
  const parsedLink = new URL(url, true);
  const {query} = parsedLink;

  parsedLink.set('query', {
    ...query,
    ico: query.ico ? `${query.ico}_${value}` : value
  });

  return parsedLink;
};

const updateMolUrlToastParams = (articleIds) => {
  const links = Array.from(document.querySelectorAll([
    '.article a:not([href*="www.thisismoney"])',
    '.article-preview a:not([href*="www.thisismoney"])'
  ].join(', ')));
  const oldArticles = new Set(articleIds);

  for (const link of links) {
    const articleId = getArticleId(link);

    if (articleId) {
      const isActive = !oldArticles.has(articleId);
      const parsedLink = asToastLink(link, isActive);

      const relativePath = parsedLink.href.replace(parsedLink.origin, '');

      link.setAttribute('href', relativePath);
    }
  }
};

const updateTimUrlToastParams = (articleIds) => {
  const links = Array.from(document.querySelectorAll([
    'a[href*="www.thisismoney"][href*="ico=mol_"]',
    'a[href*="www.thisismoney"][href*="ico=topics_pagination_"]',
    'a[href*="www.thisismoney"][href*="ico=authors_pagination_"]'
  ].join(', ')));

  const oldArticles = new Set(articleIds);

  for (const link of links) {
    const articleId = getArticleId(link);

    if (articleId) {
      const isActive = !oldArticles.has(articleId);
      const parsedLink = asToastLink(link, isActive);

      link.setAttribute('href', parsedLink.href);
    }
  }
};

const mutateTimUrlParams = () => {
  const links = Array.from(document.querySelectorAll([
    'a[href*="www.thisismoney"][href*="ico=mol_"]:not([data-mutated-tim-url-param])',
    'a[href*="www.thisismoney"][href*="ico=topics_pagination_"]:not([data-mutated-tim-url-param])',
    'a[href*="www.thisismoney"][href*="ico=authors_pagination_"]:not([data-mutated-tim-url-param])'
  ].join(', ')));

  for (const link of links) {
    const linkUrl = link.getAttribute('href');
    const parsedLink = new URL(linkUrl, true);
    const query = parsedLink.query;
    const updatedIco = `${query.ico}-newtab`;

    query.ico = updatedIco;
    query.molReferrerUrl = location.href;
    parsedLink.set('query', query);

    link.setAttribute('href', parsedLink.href);
    link.dataset.mutatedTimUrlParam = true;
    link.target = '_blank';

    const commentsLink = link.parentNode.querySelector('.comments-count > a:not([data-mutated-tim-url-param])');

    if (commentsLink) {
      const hash = commentsLink.getAttribute('href').split('#')[1];

      commentsLink.setAttribute('href', `${parsedLink.href}#${hash}`);
      commentsLink.dataset.mutatedTimUrlParam = true;
      commentsLink.target = '_blank';
    }
  }

  setTimeout(mutateTimUrlParams, 1000);
};

const updateToastLinks = (event) => {
  const ids = event.detail.ids;

  if (ids && ids.length > 0) {
    updateMolUrlToastParams(ids);
    updateTimUrlToastParams(ids);
  }

  window.removeEventListener('TOAST_OVERLAY_IDS', updateToastLinks);
};

export const updateAnchorLinks = () => {
  const {domain, renderPlatform} = getPageMetadata();

  if (domain !== 'thisismoney') {
    mutateTimUrlParams();

    if (renderPlatform === 'desktop') {
      window.addEventListener('TOAST_OVERLAY_IDS', updateToastLinks);
    }
  }
};

export const init = () => {
  const {domain} = getPageMetadata();

  if (domain !== 'thisismoney') {
    return;
  }

  const session = getStorageSession();
  const {updated, referrers, origin} = session || {};
  const referrerInSession = referrers && document.referrer && referrers.includes(document.referrer);
  const pageInSession = referrers && referrers.includes(location.href);
  const referrerIsOrigin = origin && document.referrer && document.referrer === origin;
  const isCurrentSession = (referrerInSession || pageInSession || referrerIsOrigin) && updated && Date.now() - updated < MAX_SESSION_LIFETIME;

  if (isCurrentSession) {
    addToPage(origin);

    const newReferrers = pageInSession ? referrers : [...referrers, location.href].slice(-50);

    setStorageSession({
      ...session,
      referrers: newReferrers,
      updated: Date.now()
    });

    return;
  }

  const parsedCurrentUrl = new URL(window.location.href, true);
  const referrerFromUrl = parsedCurrentUrl && parsedCurrentUrl.query && parsedCurrentUrl.query.molReferrerUrl;
  const referrer = referrerFromUrl || document.referrer;
  const parsedReferrer = referrer ? new URL(referrer, true) : null;
  const ico = parsedCurrentUrl && parsedCurrentUrl.query ? parsedCurrentUrl.query.ico : null;
  const cameFromMolOnThisPage = parsedReferrer &&
    parsedReferrer.hostname.match(MOL_DOMAIN_REGEX) &&
    ico &&
    (ico.startsWith('mol_') || ico.startsWith('topics_pagination_') || ico.startsWith('authors_pagination_'));

  if (cameFromMolOnThisPage) {
    setStorageSession({
      origin: referrer,
      referrers: [location.href],
      updated: Date.now()
    });
    addToPage(referrer);
  }
};
