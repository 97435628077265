import {later} from '@mol-fe/mol-fe-async';

let pageMetadata = {};

const getPageMetadata = function (overrides) {
  return Object.assign({}, pageMetadata, overrides);
};

const setPageMetadata = function (newPageMetadata) {
  pageMetadata = newPageMetadata;
  later.go('PAGE_METADATA_SET');
};

const getPageCriteria = function () {
  return Object.assign({}, window.PageCriteria);
};

const getArticleFeatures = function () {
  const articleFeatures = [];

  const {liveBlog, hasLiveBlogHeaderXpmodule, hasVideoHeroXpmodule} = window.PageCriteria;

  if (liveBlog) {
    articleFeatures.push('liveBlog');
  }

  if (hasLiveBlogHeaderXpmodule) {
    articleFeatures.push('hasLiveBlogHeaderXpmodule');
  }

  if (hasVideoHeroXpmodule) {
    articleFeatures.push('hasVideoHeroXpmodule');
  }

  return articleFeatures.join(',');
};

export {
  getPageCriteria,
  getPageMetadata,
  getArticleFeatures,
  setPageMetadata
};
