import contactForm from './contactForm';
import fidelity from './fidelity';
import floatingButtons from './floatingButtons';
import gdpr from './gdpr';
import misc from './misc';
import pageAd from './pageAd';
import social from './social';
import video from './video';
import webPush from './webPush';
import remixd from './remixd';
import guestbook from './guestbook';
import openweb from './openweb';
import promotionalSlot from './promotionalSlot';
import swipe from './swipe';
import comments from './comments';
import masthead from './masthead';
import commerceXp from './commerceXp';
import commentCta from './commentCta';
import mobileMenu from './mobileMenu';
import furniture from './furniture';
import articleTruncation from './articleTruncation';
import pageOverlay from './pageOverlay';
import fireworkVideoCarousel from './fireworkVideoCarousel';
import puzzle from './puzzle';
import articlePreviewXp from './articlePreviewXp';
import relatedReplace from './relatedReplace';
import cmpEssential from './cmpEssential';
import paywall from './paywall';
import factbox from './factbox';
import iframeEmail from './iframeEmail';

const handlers = Object.assign(
  {},
  contactForm,
  fidelity,
  floatingButtons,
  fireworkVideoCarousel,
  gdpr,
  misc,
  pageAd,
  social,
  video,
  webPush,
  remixd,
  guestbook,
  openweb,
  promotionalSlot,
  masthead,
  commerceXp,
  swipe,
  comments,
  commentCta,
  mobileMenu,
  furniture,
  articleTruncation,
  pageOverlay,
  puzzle,
  articlePreviewXp,
  paywall,
  relatedReplace,
  cmpEssential,
  factbox,
  iframeEmail
);

export default handlers;
